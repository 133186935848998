import React from 'react';

function popupWindow(url, title, w, h) {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom},
    height=${h / systemZoom},
    top=${top},
    left=${left}
    `
  );

  if (window.focus) newWindow.focus();
}

const knownSocialNetworks = {
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  twitter: 'https://twitter.com/intent/tweet?url=',
  linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=',
};

const ShareButton = ({ social, sharerUrl, pageUrl, children, ...rest }) => {
  let url = '';
  if (social && knownSocialNetworks[social]) {
    url = knownSocialNetworks[social];
  } else if (sharerUrl) {
    url = sharerUrl;
  } else {
    console.error(
      '<ShareButton> must be passed either a valid `social` (facebook | twitter | linkedin) or `sharerUrl` property.'
    );
    return null;
  }

  let sharingUrl = '';
  if (!pageUrl && typeof window === 'undefined') {
    console.error(
      '<ShareButton> should be passed a valid `pageUrl` to run at build time, when `window`is undefined. If you are using Gatsby see how at: https://css-tricks.com/how-to-the-get-current-page-url-in-gatsby/'
    );
  } else {
    sharingUrl = pageUrl ? pageUrl : window.location;
  }

  url += encodeURIComponent(sharingUrl);

  return (
    <a
      href={url}
      target="Share Me"
      rel="noopener noreferrer"
      onClick={e => {
        e.preventDefault();
        popupWindow(url, 'Share Me', 550, 300);
      }}
      {...rest}
    >
      {children}
    </a>
  );
};

export default ShareButton;
