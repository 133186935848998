import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash.get';
import { parseISO, format } from 'date-fns';
import { RichText } from 'prismic-reactjs';
import Layout from '../components/Layout';
import PageHeadSection from '../components/PageHeadSection';
import SEO from '../components/SEO';
import ShareButton from '../components/ShareButton';
import linkResolver from '../util/prismicLinkResolver';

export const query = graphql`
  query PageQuery($uid: String) {
    prismic {
      posts: allBlog_posts(uid: $uid) {
        edges {
          node {
            title
            extract
            date
            content
            _meta {
              id
              uid
              type
            }
            cover_image
            cover_imageSharp {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        image
        siteUrl
      }
    }
  }
`;

const News = props => {
  const doc = props.data.prismic.posts.edges.slice(0, 1).pop();
  if (!doc) return null;

  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const pageUrl = `${siteUrl}${props.location.pathname}`;

  return (
    <Layout>
      <SEO
        title={get(doc.node, 'title.0.text', '---')}
        description={get(doc.node, 'extract.0.text', '---')}
        image={doc.node.cover_image.url}
      />
      <PageHeadSection
        title="News"
        image={
          <Img
            fluid={doc.node.cover_imageSharp.childImageSharp.fluid}
            alt={doc.node.cover_image.alt}
          />
        }
      />
      <section
        className="SectionComponent hero section is-block is-relative is-white is-medium"
        data-aos="fade-up"
      >
        <div className="container">
          <div className="content">
            <p class="subtitle has-text-grey">
              {format(parseISO(doc.node.date), 'MMMM d')}
            </p>
            <h1 class="title is-1 is-size-2-mobile">
              {get(doc.node, 'title.0.text', '---')}
            </h1>
            {RichText.render(doc.node.content, linkResolver)}
            <br />
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <Link class="button is-primary is-outlined" to="/news">
                    <i class="fas fa-angle-left"></i>&nbsp;&nbsp;Back to News
                  </Link>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <b>Share this article:</b>
                </div>
                <div className="level-item">
                  <ShareButton
                    social="twitter"
                    className="share-icon is-twitter"
                    pageUrl={pageUrl}
                  >
                    <i class="fab fa-twitter-square" aria-label="Twitter"></i>
                  </ShareButton>
                </div>
                <div className="level-item">
                  <ShareButton
                    social="facebook"
                    className="share-icon is-facebook"
                    pageUrl={pageUrl}
                  >
                    <i class="fab fa-facebook-square" aria-label="Facebook"></i>
                  </ShareButton>
                </div>
                <div className="level-item">
                  <ShareButton
                    social="linkedin"
                    className="share-icon is-linkedin"
                    pageUrl={pageUrl}
                  >
                    <i class="fab fa-linkedin" aria-label="LinkedIn"></i>
                  </ShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default News;
